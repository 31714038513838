/*global $*/
/*eslint no-undef: "error"*/
export default class ServiceTab {
    classItem = 'service-tab--item'

    classButton = 'service-tab--button'
    classButtonActive = 'tab-service__tab---active'

    classBlock = 'service-tab--block'
    classBlockActive = 'service-tab__block---active'

    classToggle = 'service-tab--toggle'

    classMobile = 'service-tab--mobile'
    classMobileActive = 'tab-service-mobile---active'

    classMobileButton = 'service-tab--mobile-button'
    classMobileText = 'service-tab--mobile-text'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data('block')

            $(this).closest(`.${self.classItem}`).find(`.${self.classButton}`).removeClass(self.classButtonActive)
            $(this).addClass(self.classButtonActive)

            $(this).closest(`.${self.classItem}`).find(`.${self.classBlock}`).removeClass(self.classBlockActive)
            $(this).closest(`.${self.classItem}`).find(`.${self.classBlock}[data-block='${block}']`).addClass(self.classBlockActive)
        })

        $(`.${self.classToggle}`).click(function () {
            $(this).closest(`.${self.classItem}`).find(`.${self.classMobile}`).toggleClass(self.classMobileActive)
        })

        $(`.${self.classMobileButton}`).click(function () {
            let block = $(this).data('block')
            let text = $(this).data('text')

            $(this).closest(`.${self.classItem}`).find(`.${self.classMobileText}`).text(text)

            $(this).closest(`.${self.classItem}`).find(`.${self.classMobile}`).removeClass(self.classMobileActive)

            $(this).closest(`.${self.classItem}`).find(`.${self.classBlock}`).removeClass(self.classBlockActive)
            $(this).closest(`.${self.classItem}`).find(`.${self.classBlock}[data-block='${block}']`).addClass(self.classBlockActive)
        })
    }
}
