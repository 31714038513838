/*global $*/
/*eslint no-undef: "error"*/
export default class Map {
    constructor () {
        $('[data-code]').mouseenter(function() {
            $('.map__district span').html($(this).attr('data-title'))
        })

        $('.map').on('click', '[data-code]', function(){
            let id = $(this).attr('data-code')
            if ($('#' + id).text() != '') {
                $('.map__district span').html($(this).attr('data-title'))
                $('[data-code]').addClass('dropfill')
                $(this).addClass('mainfill')
                $('.map').addClass('open')
                $('#' + id).fadeIn()
            }
        })

        $('[data-code]').mouseenter(function() {
            $('.map__district span').html($(this).attr('data-title'))
        })

        $('.map').on('click', '[data-code]', function(){
            let id = $(this).attr('data-code')
            if ($('#' + id).text() != '') {
                $('.map__district span').html($(this).attr('data-title'))
                $('[data-code]').addClass('dropfill')
                $(this).addClass('mainfill')
                $('.map').addClass('open')
                $('#' + id).fadeIn()
            }
        })
    }
}
