/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import './assets/js/css/ImportCss'
import { Fancybox } from '@fancyapps/ui'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import ServiceTab from '@/assets/js/Service/Tab/ServiceTab'
import Map from '@/assets/js/Map/Map'
import Block from '@/assets/js/Block/Block'
import ButtonTop from '@/assets/js/Button/Top/ButtonTop'
import HeaderMobile from '@/assets/js/Header/Mobile/HeaderMobile'
import Faq from '@/assets/js/Faq/Faq'
import AppFormPopup from '@/components/Form/AppFormPopup.vue'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-fancybox]', {
        mainClass: 'fancybox--popup'
    })

    Fancybox.bind('[data-fancybox-form]', {
        mainClass: 'fancybox---form'
    })

    new SwiperSlider()
    new ServiceTab()
    new Map()
    new Block()
    new ButtonTop()
    new HeaderMobile()
    new Faq()

    if(document.getElementById('form--popup') != null) {
        createApp({})
            .component('form-popup', AppFormPopup)
            .use(Maska)
            .use(form)
            .mount('#form--popup')
    }
})
