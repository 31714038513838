/*global $*/
/*eslint no-undef: "error"*/
export default class HeaderMobile {
    classItem = 'header-mobile--item'

    classButton = 'header-mobile--button'

    classToggle = 'toggle-menu--button'
    classToggleOpen = 'toggle-menu---open'

    classItemOpen = 'header-mobile---open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(`.${self.classItem}`).toggleClass(self.classItemOpen)

            $(`.${self.classToggle}`).removeClass(self.classToggleOpen)
        })

        $(`.${self.classToggle}`).click(function () {
            $(this).toggleClass(self.classToggleOpen)
        })
    }
}
