/*global $*/
/*eslint no-undef: "error"*/

export default class Block {
    classButton = 'block--button'
    classHeader = 'page---header'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data('block')
            let heightHeader = 0

            if ($(`.${self.classHeader}`).length > 0) {
                heightHeader = $(`.${self.classHeader}`).height()
            }

            $('html,body').stop().animate(
                {
                    scrollTop: $(`#${block}`).offset().top - heightHeader -  20
                },
                500
            )
        })
    }
}
